export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [


    {
        key: 'apps-projects',
        label: 'Projects',
        isTitle: false,
        icon: 'uil-home-alt',
        children: [

            {
                key: 'project-details',
                label: 'Protocol Details',
                url: '/apps/projects/details',
                parentKey: 'apps-projects',
            },

            {
                key: 'project-create-project',
                label: 'Create New Study',
                url: '/apps/projects/new',
                parentKey: 'apps-projects',
            },
            {
                key: 'project-list',
                label: 'Study Tracking',
                url: '/apps/projects/list',
                parentKey: 'apps-projects',
            },
            {
                key: 'project-gantt',
                label: 'Study Gantt',
                url: '/apps/projects/gantt',
                badge: { variant: 'light', text: 'New' },
                parentKey: 'apps-projects',
            },
        ],
    },
    {
        key: 'charts',
        label: 'Overview Analysis',
        isTitle: false,
        icon: 'uil-chart',
        children: [
            // {
            //     key: 'chart-apex',
            //     label: 'Notebooks',
            //     url: '/charts/apex',
            //     parentKey: 'charts',
            // },

            {
                key: 'chart-novo',
                label: 'Insights',
                url: '/charts/novo',
                parentKey: 'charts',
            },
            {
                key: 'page-timeline',
                label: 'Monitoring',
                url: '/charts/timeline',
                parentKey: 'pages',
            },
            {
                key: 'page-starter',
                label: 'Digital Twin',
                url: '/charts/starter',
                parentKey: 'pages',
            },
            {
                key: 'page-timeline',
                label: 'Colaboration',
                url: '/charts/chat',
                parentKey: 'pages',
            },

        ],
    }














];

export { MENU_ITEMS };
